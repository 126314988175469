<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="产品列表" name="first">
        <Tab1 :title_info="title" :propInfo="propInfo" :classify="2" v-if="activeName == 'first'"></Tab1>
      </el-tab-pane>
      <el-tab-pane label="产品分类" name="second">
        <Tab2 :classify="2" v-if="activeName == 'second'"></Tab2>
      </el-tab-pane>
      <el-tab-pane label="草稿箱" name="three">
        <Tab3 :title_info="title" :classify="2" v-if="activeName == 'three'"></Tab3>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tab1 from "./customerArticles1.vue";
import Tab2 from "./components/tabs2.vue";
import Tab3 from "./isDrafts.vue";
export default {
  data() {
    return {
      title:'产品',
      activeName: "first", //tabs页
      propInfo:{
        iptTitle:'产品'
      },
    };
  },
  components: {
    Tab1,
    Tab2,
    Tab3
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
